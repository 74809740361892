@import url("https://fonts.googleapis.com/css2?family=Sen&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
* {
  font-family: Sen !important;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button{
  -webkit-appearance:none ;
  margin:0
}

input:focus,
select:focus,
textarea:focus {
  @apply outline-none;
}

.slide-left {
  animation: slide-left 2s ease-in-out 0s 1 normal forwards;
}
.slide-right {
  animation: slide-right 2s ease-in-out 0s 1 normal forwards;
}
.slide-and-hide {
  animation: slide-and-hide 1s ease-in-out 0s 1 normal forwards;
}

.loader{
  animation: loader 1s ease-in 0s infinite normal forwards;
}

@keyframes slide-left {
  from {
    width: 250px;
  }
  to {
    width: 100px;
  }
}

@keyframes slide-right {
  from {
    width: 100px;
  }
  to {
    width: 250px;
  }
}

@keyframes slide-and-hide {
  from {
    width: 100%;
    display: block;
  }

  to {
    width: 0;
    display: none !important;
  }
}

.slide-up {
  animation: slide-up 60ms ease-in 20ms 1 forwards alternate;
}

@keyframes slide-up {
  from {
    @apply -bottom-10 -right-10;
  }

  to {
    @apply bottom-5 right-4;
  }
}

@keyframes loader {
  from {
    width: 10px;
    height: 5px;
    background-color: white;
  }
  to {
    width: 180px;
    height: 5px;
    background-color: orange;
   border-radius: 2%;
  }
}
